/** @jsx jsx */
import { jsx, Grid, Box } from 'theme-ui'
import { FC, useRef, useState } from 'react'
import {
  GroupCollectionTemplateType,
  ProductItemComponentType,
  ProductItemType,
} from '~/@types/models'
import CollectionLayout from '~/components/layout/collection-layout'
import CollectionProducts from '~/containers/collection-products'
import { space } from '~/gatsby-plugin-theme-ui'
import ProductItem from '~/components/product-item'
import { H2, H3 } from '~/styled/components/typography'
import { Button } from '~/components/button'
import { Link } from 'gatsby'
import { ImageSize } from '~/components/product-item/types'
import { bannerInject } from '~/componentsV2/cmsBanners/utils'
import { useIdentifierModal } from '~/context/identifier-modal'
import { addWatermarkAndDownload } from '~/componentsV2/contextMenu/ImageWatermark'
import CustomContextMenu from '~/componentsV2/contextMenu'

type Props = GroupCollectionTemplateType

const CollectionGroup: FC<Props> = ({ pageContext: { data } }) => {
  const { customerSegment } = useIdentifierModal()
  const [clickedItem, setClickedItem] = useState<ProductItemComponentType | null>(null)
  const contextMenuRef = useRef<HTMLDivElement>(null)

  return (
    <CollectionLayout
      title={data.title}
      seoTitle={data.seo?.title || data.title}
      seoDescription={data.seo?.description || data.description}
      description={data.description}
      handle={data.handle}
      ankleContent={data.metafields?.ankleContent}
      headerDescription={data.metafields?.headerDescription}
      heroHeader={{
        isExternal: true,
        ...(data.imageResponsive && {
          imageSet: data.imageResponsive,
        }),
      }}
    >
      <CustomContextMenu
        ref={contextMenuRef}
        actions={[
          {
            label: 'Go to Product',
            group: 'Product',
            onClick: () => {
              window.open(`/products/${clickedItem?.handle}`)
            },
          },
          {
            label: 'Save Image',
            group: 'Image',
            onClick: () => {
              const watermarkText = window.location.origin
              const logoUrl = '/media/2024/logo/ben-logo.jpeg'
              addWatermarkAndDownload(
                clickedItem?.mainImage.srcDesktop || '',
                watermarkText,
                logoUrl,
                clickedItem?.title
              )
            },
          },
        ]}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 60,
            marginTop: [space.md1, null],
          }}
        >
          {data.groups.map(group => {
            return (
              <Box key={group.productType} mb={space.sm}>
                <H2
                  style={{
                    textTransform: 'uppercase',
                  }}
                >
                  {group.title}
                  <Link className="small" to={group.link}>
                    SHOP ALL
                  </Link>
                </H2>
                <Box
                  sx={{
                    marginTop: [space.md1, null],
                  }}
                >
                  <Grid
                    variant="layout.template-furniture"
                    sx={{
                      alignItems: 'flex-start',
                    }}
                  >
                    {group.products.map(item => {
                      return (
                        <Box key={group.productType} mb={space.sm}>
                          <ProductItem
                            product={item}
                            imageSize={ImageSize.OVERHEAD}
                            footerNoMargin={true}
                            tag={item.metafields.cardCollectionTag}
                            onItemClick={() => setClickedItem(item)}
                          />
                        </Box>
                      )
                    })}
                  </Grid>
                </Box>
                <Button
                  label={`Explore ${group.title}`}
                  as="a"
                  to={group.link}
                  sxProps={{
                    background: '#F8F8F8',
                    border: '0.50px #E5E5E5 solid',
                    color: 'black',
                    margin: '40px auto',
                    width: 469,
                    maxWidth: 'calc(100% - 40px)',
                    fontSize: 18,
                    fontWeight: 500,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    '&:hover': {
                      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    },
                    span: {
                      color: '#595858',
                      fontFamily: 'Roboto',
                      fontSize: 16,
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: 'normal',
                      letterSpacing: '0.8px',
                      textTransform: 'uppercase',
                    },
                  }}
                />
              </Box>
            )
          })}
        </Box>
      </CustomContextMenu>
    </CollectionLayout>
  )
}

export default CollectionGroup
